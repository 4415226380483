import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { initialize, reduxForm, Field, change } from "redux-form";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import M from "materialize-css";
import PageTitle from "../../components/UI/PageTitle";
import PageContainer from "../../components/UI/PageContainer";
import ContentContainer from "../../components/UI/ContentContainer";
import Spacer from "../../components/UI/Spacer";
import FormButton from "../../components/UI/FormButton";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OpenFormationsModal from './components/openFormationsModal';
import OpenInscripcionsModal  from './components/openInscripcionsModal';


import {
  renderFieldTwoColumns,
  renderFieldOneColumn,
} from "../../components/UI/Fileds";

import { fetchEvents, plannerAll } from "../../actions/planner";

import {
  fetchCourses,
  selectCourse,
  fetchFilesAttachedDeals,
  fetchNotesAttachedDeals,
  downloadDealFilesAttached,
  setCourseFilter,
  resetAlertDeal,
  deleteGroup,
} from "../../actions/course";
import {
  startLoading,
  endLoading,
  fetchDemarcacionList,
  fetchCenterList,
  fetchTrainerList,
} from "../../actions/ui";
import {
  showError,
  showConfirmation,
  showSuccess,
} from "../../helpers/messages";

import * as Icons from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoadingScreen from "../../hooks/common/loadingScreen";
import CustomMaterialTable from "../../components/UI/CustomMaterialTable";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import Schedule from "@material-ui/icons/Schedule";
import FileUpload from "@material-ui/icons/CloudUpload";
import People from "@material-ui/icons/People";
import AttachmentListView from "./components/AttachmentListView";
import NotesListView from "./components/NotesListView";
import CustomTabContainer from "../../components/UI/CustomTabContainer";
import AttachmentManager from "../../components/UI/AttachmentManager";
import ProductExtrasView from "./components/ProductExtrasView";
import { set } from "lodash";

//Todo:
// 1. Define fields for the form
// 2. Define validation for the form
// 3. Define submit function for the form
// 4. Define cancel function for the form
// 5. Define render function for the form
// 6. Define edit function for the form
// 7. Define new function for the form
// 8. Define delete function for the form
// 9. Define search function for the form
// 10. Refactor code use solid principles
// 11. Refactor code use best practices
// 12. Refactor code use design patterns
// 13. Refactor code use clean code
// 14. Refactor code use clean architecture

const ScreensPlannerFormGroup = ({ submitting, handleSubmit, dirty }) => {
  // const fileInputFoto = React.createRef();

  // const fileInputAdjunto = React.createRef();

  const { t } = useTranslation();

  const { user: userLogged } = useSelector((state) => state.auth);
  const { eventsList } = useSelector((state) => state.planner);
  const { eventFilter } = useSelector((state) => state.planner);
  const [events, setEvents] = useState([]);

  // course

  const { list, selected, list_files, list_notes } = useSelector(
    (state) => state.course
  );

  // occupation
  const { occupationsList } = useSelector((state) => state.planner);
  const { occupationFilter } = useSelector((state) => state.planner);
  const [occupations, setOccupations] = useState([]);

  // const { selected } = useSelector((state) => state.student);

  const [user, setUser] = useState(
    selected && selected.user
      ? selected.user
      : { id: "", label: t("selector-user-no-selected") }
  );

  const { demarcacionList } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  const history = useHistory();
  const [sessions, setSessions] = useState([]);
  const [grouplist, setGroupList] = useState([]);
  const [isGroupListFetched, setIsGroupListFetched] = useState(false);
  //planner index
  const [currentIndex, setCurrentIndex] = useState(null);
  // planner dialog
  const [openDialog, setOpenDialog] = useState(false);

  // planner state
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [occupiedTimeSlots, setOccupiedTimeSlots] = useState({});
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isEventPending, setIsEventPending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEventsListFetched, setIsEventsListFetched] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // trainer list
  const { trainerList } = useSelector((state) => state.ui);

  //Attachements Modal
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  // Open Formations Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  
  // Open Alumnos Inscriptos Modal
  const [isModalInscripsionVisible, setIsModalInscripsionVisible] = useState(false);
  const [selectedCourseIdIncriptio, setSelectedCourseIdIncriptio] = useState(null);

  // planner filter
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultColumns = [
    { title: "Grupo", field: "meses" },
    {
      title: "Nombre de la Formación",
      field: "name",
      hidden: isMobile,
      width: "45%",
    },
    // { title: "Estado", field: "status", hidden: isMobile },
    {
      title: "Cupo máximo de Alumnos",
      field: "maxOfStudents",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Alumnos inscriptos",
      field: "numberOfStudents",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Planificadas",
      field: "plannerQuantity",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Reservadas",
      field: "draftQuantity",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Suspendidas",
      field: "suspendedQuantity",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Canceladas",
      field: "canceledQuantity",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Finalizadas",
      field: "finishedQuantity",
      hidden: isMobile,
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
    },
  ];



  const addOrRemoveColumns = (columns, action, newColumns) => {
    if (action === 'add') {
      return [...columns, ...newColumns];
    } else if (action === 'remove') {
      return columns.filter(col => !newColumns.find(newCol => newCol.field === col.field));
    }
    return columns;
  };


  let modifiedColumns;
  const tipoFormacion = selected && selected.gep_tipo_formacion ? selected.gep_tipo_formacion : 'default';

  switch (tipoFormacion) {
    case 'GEP SERVICES':
      // Agregar o quitar columnas para Tipo
      modifiedColumns = addOrRemoveColumns(defaultColumns, 'remove', [{ field: 'maxOfStudents' }]);
      modifiedColumns = addOrRemoveColumns(modifiedColumns, 'remove', [{ field: 'numberOfStudents' }]);
      modifiedColumns = addOrRemoveColumns(modifiedColumns, 'remove', [{ field: 'name' }]);
      modifiedColumns = addOrRemoveColumns(modifiedColumns, 'add', [{ title: "Preventivo", field: "name", hidden: isMobile, width: "45%" }]);

      break;

    default:
      modifiedColumns = [...defaultColumns];
  }


  let columns = modifiedColumns;




  const [filter, setFilter] = useState("");

  //   "estado_general_formacion": [
  //     {
  //         "estado": "planificadas",
  //         "cant": 0
  //     },
  //     {
  //         "estado": "borrador",
  //         "cant": 0
  //     },
  //     {
  //         "estado": "suspendidas",
  //         "cant": 0
  //     },
  //     {
  //         "estado": "canceladas",
  //         "cant": 0
  //     },
  //     {
  //         "estado": "finalizadas",
  //         "cant": 0
  //     }
  // ]

  const getQuantity = (group, status) => {
    if (
      group.estado_general_formacion &&
      Array.isArray(group.estado_general_formacion)
    ) {
      const statusItem = group.estado_general_formacion.find(
        (item) => item.estado === status
      );
      return statusItem ? statusItem.cant : 0;
    }
    return 0;
  };

  // const data = list;
  // const getFilteredData = () => {
  //   // return data.filter((item) => item.name.toLowerCase().includes(filter));
  //   //implementar filtro por meses
  //   // filter improve performance
  //   // find if user put more than 3 characters
  //   if (filter.length < 3) {
  //     return data;
  //   }
  //   return data.filter((item) => item.meses.toLowerCase().includes(filter));
  // };

  // const filteredData = getFilteredData();

  // useEffect(() => {
  //   fetchData().then(() => {
  //     setIsLoading(false);
  //   });
  // }, []);

  // planner handle alert

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleLoadIncriptions = (group) => {
    dispatch(selectCourse(group));
    //if (group.cursos_tipo.nombre === 'Presencial' || group.cursos_tipo.nombre === 'Taller') {
    history.push("/cursos/carga-inscripciones-presencial");
    //} else if (group.cursos_tipo.nombre === 'Videoconferencia') {
    //history.push('/cursos/carga-inscripciones-video');
    //}
  };

  // planner settings time slots

  // planner settings time slots
  const timeSlots = [
    { id: "Mañana", label: "Mañana", start: "7:00", end: "13:00" },
    { id: "Tarde", label: "Tarde", start: "13:00", end: "19:00" },
    { id: "Todo el día", label: "Todo el día", start: "7:00", end: "19:00" },
  ];

  //set center list
  useEffect(() => {
    dispatch(startLoading());
    dispatch(fetchCenterList()).then((res) => {
      dispatch(endLoading());
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    });
  }, [dispatch, t]);

  //set trainer list
  useEffect(() => {
    dispatch(startLoading());
    dispatch(fetchTrainerList()).then((res) => {
      dispatch(endLoading());
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    });
  }, [dispatch, t]);

  // planner determine time slot
  const determineTimeSlot = (event) => {
    const eventStart = new Date(event.f_inicio);
    const eventStartHours = new Date(event.f_inicio).getUTCHours();
    const eventStartMinutes = new Date(event.f_inicio).getUTCMinutes();
    eventStart.setHours(eventStartHours);
    eventStart.setMinutes(eventStartMinutes);
    eventStart.setSeconds(0);

    const eventEnd = new Date(event.f_fin);
    const eventEndHours = new Date(event.f_fin).getUTCHours();
    const eventEndMinutes = new Date(event.f_fin).getUTCMinutes();
    eventEnd.setHours(eventEndHours);
    eventEnd.setMinutes(eventEndMinutes);
    eventEnd.setSeconds(0);

    for (const timeSlot of timeSlots) {
      const [startHours, startMinutes] = timeSlot.start.split(":").map(Number);
      const [endHours, endMinutes] = timeSlot.end.split(":").map(Number);

      const slotStart = new Date(eventStart);
      slotStart.setHours(startHours, startMinutes, 0);

      const slotEnd = new Date(eventStart);
      slotEnd.setHours(endHours, endMinutes, 0);

      if (eventStart >= slotStart && eventEnd <= slotEnd) {
        return timeSlot.label;
      }
    }

    return null;
  };

  //set occupations list
  useEffect(() => {
    if (!occupationsList) {
      return;
    }

    const occupiedTimeSlots = occupationsList.reduce((acc, event) => {
      const date = new Date(event.f_inicio).toISOString().split("T")[0];
      const timeSlot = determineTimeSlot(event);

      if (!timeSlot) {
        return acc;
      }

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(timeSlot);

      return acc;
    }, {});

    // setIsLoading(false);
    setOccupiedTimeSlots(occupiedTimeSlots);
  }, [occupationsList]);

  // Update sessions when eventsList is fetched
  useEffect(() => {
    if (!selected) {
      // return planner
      history.goBack();
    }
    if (isGroupListFetched) {
      const mappedGroups = list.map((group, index) => ({
        groupNumber: index + 1,
        id: group.id,
        dealId: group.deal_id_pipedrive,
        courseTitle: group.titulo,
        courseStudent: group.codigo_grupo_alumnos,
        //status: group.planificado ? "Planificado" : "Sin Planificar",
        // status: group.estado_general_formacion,
        numberOfStudents: group.ocupadas ? group.ocupadas : 0,
        maxOfStudents: group.plazas ? group.plazas : 0,
        name: group.gep_tipo_formacion === 'GEP SERVICES' ? `(${group.p_tipo_servicio}) - ${group.titulo}` : group.titulo,
        meses: group.meses,
        p_titulo_deal: group.p_titulo_deal + " - " + group.p_owner_name + "",

        plannerQuantity: getQuantity(group, "planificadas"),
        draftQuantity: getQuantity(group, "borrador"),
        suspendedQuantity: getQuantity(group, "suspendidas"),
        canceledQuantity: getQuantity(group, "canceladas"),
        finishedQuantity: getQuantity(group, "finalizadas"),
        p_productos_extras: group.p_productos_extras

        // startDate: new Date(event.f_inicio),
        // endDate: new Date(event.f_fin),
        // center: event.centro_id,
        // room: event.sala_id,
        // timeSlot: event.turno,
        // trainer: event.trainer_id,
        // estados: event.estados,
        // disabled: true,
      }));
      // sort by group code
      mappedGroups.sort((a, b) => (a.courseStudent > b.courseStudent ? 1 : -1));

      setGroupList(mappedGroups);
      setIsEventsListFetched(false); // Reset for next update
      setIsLoading(false);
    }
  }, [isGroupListFetched, list]);

  useEffect(() => {
    dispatch(fetchDemarcacionList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });

    if (selected) {
      dispatch(
        initialize("plannerFormGroup", {
          id: selected.id,
          nombreCurso: selected.titulo,
          dealId: selected.deal_id_pipedrive,
          cliente: selected.customer_name_pipedrive
            ? selected.customer_name_pipedrive
            : "",
          url_custumer_pipedrive: selected.url_custumer_pipedrive
            ? selected.url_custumer_pipedrive
            : "",
          tipo: selected.gep_tipo_formacion ? selected.gep_tipo_formacion : "",
          fecha_inicio: selected.fecha_inicio,
          estado: selected.planificado ? "Planificado" : "No Planificado",
          courseStudent: selected.codigo_grupo_alumnos,
          p_titulo_deal:
            selected.p_titulo_deal + " - " + selected.p_owner_name + "",
          p_fundae: selected.p_fundae ? "SI" : "NO",
          p_caes: selected.p_caes ? "SI" : "NO",
          p_hotel_pernocta: selected.p_hotel_pernocta ? "SI" : "NO",
        })
      );

      // Fetch new eventsList here
      if (selected.id) {
        dispatch(startLoading());
        dispatch(
          fetchCourses({ deal_id_pipedrive: selected.deal_id_pipedrive })
        ).then((res) => {
          dispatch(endLoading());
          if (!res.ok) {
            showError(t("message-title-error"), t(res.message));
          }
          setIsGroupListFetched(true);
        });

        dispatch(fetchFilesAttachedDeals(selected.deal_id_pipedrive)).then(
          (res) => {
            dispatch(endLoading());
            if (!res.ok) {
              showError(
                t("message-title-error"),
                t("Error en la carga de ficheros.")
              );
            }
          }
        );

        dispatch(fetchNotesAttachedDeals(selected.deal_id_pipedrive)).then(
          (res) => {
            dispatch(endLoading());
            if (!res.ok) {
              showError(
                t("message-title-error"),
                t("Error en la carga de notas de pipedrive.")
              );
            }
          }
        );
      }

      if (selected.user) {
        setUser(selected.user);
        dispatch(change("plannerFormGroup", "user_id", selected.user.id));
      }
    } else {
      dispatch(initialize("plannerFormGroup", {}));
    }

    const elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {});
  }, [dispatch, selected]);

  const handleSend = (values) => {
    if (!values.id || values.id === "" || isEventPending) {
      showError(t("message-title-error"), t("message-error-course-id-empty"));
      return;
    }

    dispatch(startLoading());
    dispatch(plannerAll(values.id)).then((res) => {
      dispatch(endLoading());

      if (res.ok) {
        showSuccess(t("message-title-success"), t(res.message));
        dispatch(fetchEvents({ curso_id: values.id })).then((res) => {
          dispatch(endLoading());
          if (!res.ok) {
          }
          setIsEventsListFetched(true);
        });

        history.goBack();
      } else {
        showError(t("message-title-error"), t(res.message));
      }
    });
  };

  const handleCancel = () => {
    if (dirty) {
      showConfirmation(
        t("message-title-confirmation"),
        t("message-abandom-form-text"),
        t("button-title-exit"),
        t("button-title-keep-here"),
        () => {
          history.goBack();
        }
      );
    } else {
      history.goBack();
    }
  };

  // planner fetch available time slots

  // planner handle date change

  // planner handle time change

  const handleDetailGroup = (group) => {
    resetForm();
    const item = findGroupById(group.id);
    if (!item) {
      return;
    }
    navigateToGroupDetail(item);
  };

  const resetForm = () => {
    dispatch(initialize("plannerFormGroup", {}));
    dispatch(selectCourse(null));
  };

  const findGroupById = (groupId) => {
    return list.find((item) => item.id === groupId);
  };

  const navigateToGroupDetail = (item) => {
    const path = getNavigationPath(item.gep_tipo_formacion);
    dispatch(selectCourse(item));
    history.push(path);
  };

  const getNavigationPath = (tipoFormacion) => {
    tipoFormacion = tipoFormacion.toUpperCase();
    return tipoFormacion === "GEP SERVICES"
      ? `/planner/formprevent`
      : `/planner/formulario/`;
  };

  // ver inscripciones
  const handleViewIncriptions = (courseId) => {
    setSelectedCourseIdIncriptio(courseId);
    setIsModalInscripsionVisible(true);
  };
 
  const handleCloseInscriptionModal = () => {
    setIsModalInscripsionVisible(false);
    setSelectedCourseIdIncriptio(null);
  };

  const handleOpenFormationsModal = (courseId) => {
    setSelectedCourseId(courseId);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedCourseId(null);
  };
  
  const handleModalAttachments = (group) => {
    setOpenAttachmentsModal(true);
    setRowSelected(group);
  };

  const handleCloseAttachmentsModal = () => {
    setOpenAttachmentsModal(false);
  };

  const handleDelete = (group) => {

    const dealId = group.dealId;
    let deleteKey = [];


    deleteKey = [
      { key: 'grupo', value: group.courseStudent }
    ];


    showConfirmation(
      t("message-title-confirmation"),
      t("message-delete-group-text", { name: group.courseStudent }),
      t("button-title-delete"),
      t("button-title-cancel"),
      () => performDelete(dealId, deleteKey)
    );
  };

  const performDelete = (dealId, deleteKeys) => {

    setIsLoading(true);
    dispatch(startLoading());

    dispatch(deleteGroup(deleteKeys))
      .then((res) => {
        if (res.ok) {
          showSuccess(t("message-title-success"), t(res.message));
          return fetchRelatedData(dealId);
        } else {
          showError(t("message-title-error"), t("message-error-delete-group"));
        }
      })
      .catch((error) => {
        showError(t("message-title-error"), error.message);
      })
      .finally(() => {
        dispatch(endLoading());
        setIsLoading(false);
      });

  };

  const fetchRelatedData = (deal_id) => {
    return Promise.all([
      dispatch(fetchCourses({ deal_id_pipedrive: deal_id })),
      dispatch(fetchFilesAttachedDeals(deal_id)),
      dispatch(fetchNotesAttachedDeals(deal_id))
    ]).then((results) => {
      if (results.some((res) => !res.ok)) {
        showError(t("message-title-error"), t("message-error-fetch-data"));
      }
    });
  };

  const configurableActions = [
    {
      key: "planner",
      icon: () => (
        <Schedule
          style={{ fontSize: "small" }}
          className="btn-floating btn-small waves-effect waves-light green darken-3"
        />
      ),
      tooltip: "Planificar",
      onClick: (event, rowData) => {
        handleDetailGroup(rowData);
      },
    },
    {
      key: "uploadStudentFile",
      icon: () => (
        <FileUpload
          style={{ fontSize: "small" }}
          className="btn-floating btn-small waves-effect waves-light orange darken-3"
        />
      ),

      tooltip: "Subir Archivo de Alumnos",
      onClick: (event, rowData) => {
        handleLoadIncriptions(rowData);
      },
    },
    {
        key: "viewInscriptions",
        icon: () => (
          <People
            style={{ fontSize: "small" }}
            className="btn-floating btn-small waves-effect waves-light pink darken-3"
          />
        ),

        tooltip: "Ver Inscripciones",
        onClick: (event, rowData) => {
          handleViewIncriptions(rowData.id);
        },
    },

    {
      key: "viewDealsAsociados",
      icon: () => (
        <BubbleChartIcon
          style={{ fontSize: "small" }}
          className="btn-floating btn-small waves-effect waves-light blue darken-3"
        />
      ),

      tooltip: "Ver Deals Asociados",      
      onClick: (event, rowData) => {
        handleOpenFormationsModal(rowData.id);
      },
    },

    // Attachments Management Modal call
    {
      key: "attachments",
      icon: () => (
        <Icons.Attachment
          style={{ fontSize: "small" }}
          className="btn-floating btn-small waves-effect waves-light blue darken-3"
        />
      ),
      tooltip: "Adjuntos",
      onClick: (event, rowData) => {
        handleModalAttachments(rowData);
      },
    },

    // delete action
    {
      key: "delete",
      icon: () => (
        <Icons.Delete
          style={{ fontSize: "small" }}
          className="btn-floating btn-small waves-effect waves-light red darken-3"
        />
      ),
      tooltip: "Eliminar",
      onClick: (event, rowData) => {
        // Handle the delete action
        handleDelete(rowData);
      },
    }
  ];
  const modifyActionsForTipoFormacion = (configurableActions, tipoFormacion) => {
    let modifiedActions = [...configurableActions]; // Copy of the original array for modification

    if (tipoFormacion === 'GEP SERVICES') {
      modifiedActions = modifiedActions.filter(action => action.key !== 'uploadStudentFile');
      modifiedActions = modifiedActions.filter(action => action.key !== 'viewInscriptions');
      modifiedActions = modifiedActions.filter(action => action.key !== 'viewDealsAsociados');

    }

    return modifiedActions;
  }

  // Modify configurableActions based on tipoFormacion
  const actions = modifyActionsForTipoFormacion(configurableActions, tipoFormacion);

  const renderForm = () => {
    return (

      <div style={{ position: "relative" }}>

        {isLoading && <LoadingScreen />}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={handleSubmit(handleSend)}>
            {/* <AppBar position={isMobile ? "fixed" : "static"} color="" style={isMobile ? { top: 'auto', bottom: 0 } : {}}> */}
            {/* <Toolbar style={{ justifyContent: "flex-end" }}> */}
            {/* Add your edit actions here */}
            {/* <IconButton color="primary"> */}
            {/* <Icons.Edit /> */}
            {/* </IconButton> */}
            {/* Save button */}
            {/* <IconButton color="red" > */}
            {/* <Icons.Save /> */}
            {/* </IconButton> */}
            {/* </Toolbar> */}
            {/* </AppBar> */}

            {/* Campos del formulario */}
            <div className="row margin">
              {/* deal id */}
              <Field
                type="text"
                name="dealId"
                id="dealId"
                label={"Deal ID"}
                component={renderFieldTwoColumns}
                icon="person"
                key="dealId"
                maxLength="128"
                editable={false}
              />
              {/* customer name */}
              <Field
                type="text"
                name="cliente"
                id="cliente"
                label={"Cliente"}
                component={renderFieldTwoColumns}
                icon="person"
                key="cliente"
                maxLength="128"
                editable={false}
              />
            </div>
            <div className="row margin">
              {/* type of training*/}

              {
                <Field
                  type="text"
                  name="p_titulo_deal"
                  id="p_titulo_deal"
                  label={"Titulo"}
                  component={renderFieldTwoColumns}
                  icon="book"
                  key="p_titulo_deal"
                  maxLength="128"
                  editable={false}
                />
              }
              <Field
                name="tipo"
                id="tipo"
                label={"Tipo de curso"}
                component={renderFieldTwoColumns}
                icon="school"
                key="tipo"
                editable={false}
              />
            </div>
            <div className="row margin">
              {
                <Field
                  type="text"
                  name="p_fundae"
                  id="p_fundae"
                  label={"FUNDAE"}
                  component={renderFieldTwoColumns}
                  icon="check"
                  key="p_fundae"
                  maxLength="128"
                  editable={false}
                />
              }
              <Field
                type="text"
                name="p_caes"
                id="p_caes"
                label={"CAES"}
                component={renderFieldTwoColumns}
                icon="check"
                key="p_caes"
                maxLength="128"
                editable={false}
              />
            </div>
            <div className="row margin">
              <Field
                type="text"
                name="p_hotel_pernocta"
                id="p_hotel_pernocta"
                label={"HOTEL PERNOCTA"}
                component={renderFieldOneColumn}
                icon="hotel"
                key="p_hotel_pernocta"
                maxLength="128"
                editable={false}
              />
            </div>

            {/* Botones del formulario */}
            <div className="row">
              <div className="input-field col s12">
                {/* <FormButton
                type="submit"
                title={"Planificar"}
                disabled={submitting}
                onClick={handleSubmit(handleSend)}
              /> */}

                <FormButton
                  type="back"
                  title={"Volver"}
                  disabled={submitting}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </form>
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const renderGroupList = () => {
    return (
      <CustomMaterialTable
        columns={columns}
        data={grouplist}
        actions={actions}
        dealId={selected?.deal_id_pipedrive}
        title="Grupos"
        detailPanel={
          isMobile
            ? [
              {
                tooltip: "Show Details",
                render: (rowData) => {
                  return (
                    <div style={{ padding: "10px" }}>
                      <div>
                        <strong>Nombre de la Formación: </strong>
                        {rowData.name}
                      </div>
                      <div>
                        <strong>Estado grupo: </strong>
                        {/* {JSON.stringify(rowData.status)} */}
                        {/* {rowData.status}  */}
                      </div>
                      <div>
                        <strong>Cupo máximo de Alumnos: </strong>
                        {rowData.maxOfStudents}
                      </div>
                      <div>
                        <strong>Alumnos inscriptos: </strong>
                        {rowData.numberOfStudents}
                      </div>
                    </div>
                  );
                },
              },
            ]
            : null
        }
        options={{
          actionsColumnIndex: -1,
          overflowY: "auto",
          overflowX: "auto",
          tableLayout: "auto",
          detailPanelColumnAlignment: "right",
        }}
      />
    );
  };

  // const attachments = [
  //   { name: "document.pdf" },
  //   { name: "image.png", createdAt: "2021-01-01", size: 1024, type: "image", url: "https://picsum.photos/200" }
  //   // { name: "song.mp3" },
  //   // { name: "video.mp4" },
  //   // { name: "file.txt" },
  //   // Add more attachments as needed
  // ];
  //
  const handleDownloadFilesApi = (id_fichero, apiFileName) => {
    dispatch(downloadDealFilesAttached(id_fichero, apiFileName));
  };

  const convertBytesToMegaBytes = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "0 Byte";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  };
  // Mapping list files
  const attachments = list_files?.map((file) => ({
    name: file.name,
    modifiedDate: file.add_time,
    size: convertBytesToMegaBytes(file.file_size),
    type: file.file_type,
    link: file.url,
    id: file.id,
  }));

  // Mapping list notes
  const notes = list_notes?.map((note) => ({
    id: note.id,
    name: note.content,
    modifiedDate: note.update_time,
    user_name: note.user.name,
  }));

  const actionDownload = [
    {
      icon: () => (
        <Icons.CloudDownload
          style={{ fontSize: "small" }}
          className="btn-floating btn-small waves-effect waves-light blue darken-3"
        />
      ),
      tooltip: "Descargar",
      onClick: (event, rowData) => {
        // Handle the edit action
        handleDownloadFilesApi(rowData.id, rowData.name);
      },
    },
  ];

  const renderAtachedFilesList = () => {
    return (
      <div style={{ position: "relative" }}>
        <AttachmentListView
          attachments={attachments}
          actions={actionDownload}
        />
      </div>
    );
  };

  const handleCloseAlert = (id) => {
    dispatch(resetAlertDeal(id)).then((res) => {
      dispatch(endLoading());
      if (res.ok) {
        document.getElementById('alertUpdatedDeal').style.display = 'none';
      } else {
        showError(t("message-title-error"), t(res.message));
      }
    });
  };

  const renderAtachedNotesList = () => {
    return (
      <div style={{ position: "relative" }}>
        <NotesListView notes={notes} />
      </div>
    );
  };


  // Get all product extras from the group list
  const getAllProductExtras = (grouplist) =>
    grouplist.flatMap(group => group.p_productos_extras || []);

  // Convert product extras to JSON
  const allProductExtras = getAllProductExtras(grouplist);
  const allProductExtrasJson = JSON.stringify(allProductExtras);

  // Render the product extras list
  const renderProductExtraList = () => (
    <div style={{ position: "relative" }}>
      <ProductExtrasView productExtras={allProductExtrasJson} />
    </div>
  );

  const tabs = [
    { label: "Listado de Grupo", content: renderGroupList() },
    { label: "Adjuntos", content: renderAtachedFilesList() },
    { label: "Notas", content: renderAtachedNotesList() },
    { label: "Productos Extra", content: renderProductExtraList() },
  ];
  return (
    <PageContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <PageTitle title={t("screens-planner-list-group-title")} />

      <ContentContainer>{renderForm()}</ContentContainer>

      {isModalVisible && (
        <OpenFormationsModal
          courseId={selectedCourseId}
          onClose={handleCloseModal}
        />
      )}

      {isModalInscripsionVisible && (
        <OpenInscripcionsModal
          courseId={selectedCourseIdIncriptio}
          onClose={handleCloseInscriptionModal}
        />
      )}

      {(selected.p_webhook_notification != null) ? (
        <div id="alertUpdatedDeal" className="card-panel waves-effect waves-dark teal #fff9c4 yellow lighten-4" style={{ width: '100%' }}>
          <IconButton style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => handleCloseAlert(selected.deal_id_pipedrive)}>
            <CloseIcon />
          </IconButton>
          <span className="grey-text">
            Este deal en PIPEDRIVE ha sido recientemente actualizado ({new Date(selected.p_webhook_notification).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}) . Te recomendamos verificar la información modificada con el comercial a cargo. Por favor, ten en cuenta que la actualización de la información en GEP Group - ERP no se realizará hasta la primera hora de mañana.
          </span>
        </div>
      ) : null}
      <CustomTabContainer tabs={tabs} />
      <AttachmentManager
        open={openAttachmentsModal}
        onClose={handleCloseAttachmentsModal}
        object={rowSelected ? rowSelected : null}
        objectType="course"
      />

      {/* <ContentContainer>{renderAtachedFilesList()}</ContentContainer>
      <CustomContentContainer title="Listado de Grupos" startCollapsed={true}>
        {renderGroupList()}
      </CustomContentContainer>
      <CustomContentContainer title="Archivos Adjuntos" startCollapsed={true}>
        {renderAtachedFilesList()}
      </CustomContentContainer> */}
      {/* <div id="modal-selector-usuario" className="modal">
        <div className="modal-content">
          <ScreensUserSelector
            onSelect={handleSelectUser}
            fetchUserList={fetchStudentUserList}
          />
        </div>
      </div> */}
      {/* 
      <div id="modal-nuevo-usuario" className="modal">
        <div className="modal-content">
          <ScreensUserNew
            onSelect={handleNewUser}
            role="ROLE_ALUMNO"
            hideEmail
          />
        </div>
      </div> */}

      <Spacer height="5" />
    </PageContainer>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.nombre) {
    errors.nombre = i18n.t("validator-error-nombre");
  }

  if (!values.apellidos) {
    errors.apellidos = i18n.t("validator-error-apellidos");
  }

  if (!values.email) {
    errors.email = i18n.t("validator-error-email");
  }

  if (!values.demarcacion_id) {
    errors.demarcacion_id = i18n.t("validator-error-demarcacion");
  }

  return errors;
};

export default reduxForm({
  form: "plannerFormGroup",
  validate: validate,
  destroyOnUnmount: false, //true
})(ScreensPlannerFormGroup);
